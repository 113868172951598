import React from "react"
import { Box, Button, Paragraph, Heading } from "grommet"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

export default function DownloadPaper(data) {
  return (
    <Layout>
      <Seo title="Downloads" />
      <Box
        margin={{ top: "xlarge" }}
        width="large"
        alignSelf="center"
        align="start"
        gap="xlarge"
      >
        <Heading
          margin={{ top: "none", bottom: "xsmall" }}
          level="1"
          size="medium"
        >
          Thanks for subscribing
        </Heading>
      </Box>
      <Box
        background="light-1"
        pad="large"
        margin={{ top: "medium", bottom: "xlarge" }}
        width="large"
        direction="row-responsive"
        alignSelf="center"
        align="start"
        gap="large"
      >
        <Box width="small">
          <Img
            objectFit="contain"
            alt={data.data.thumbnail.name}
            fluid={data.data.thumbnail.childImageSharp.fluid}
          />
        </Box>
        <Box fill>
          <Heading
            margin={{ top: "none", bottom: "xsmall" }}
            level="2"
            size="small"
          >
            Econsultancy's Best Practice Guide to Rapid Innovation
          </Heading>
          <Paragraph fill size="small">
            Credit to our good friend and ex colleague{" "}
            <a
              href="https://www.linkedin.com/in/fergusroche/"
              target="_blank"
              rel="noreferrer"
            >
              Fergus&nbsp;Roche
            </a>{" "}
            as UX expert/lead author on the paper.
          </Paragraph>
          <p>
            <Button
              target="_blank"
              rel="noopener noreferrer"
              href={data.data.pdf.publicURL}
              color="brand"
              primary
              size="large"
              label="Download"
            />
          </p>
        </Box>
      </Box>
    </Layout>
  )
}
export const query = graphql`
  query DownloadQuery {
    pdf: file(
      relativePath: {
        eq: "static/assets/econsultancy-innovation-through-rapid.pdf"
      }
    ) {
      publicURL
      prettySize
    }
    thumbnail: file(
      relativePath: {
        eq: "static/images/econsultancy-innovation-through-rapid.png"
      }
    ) {
      name
      childImageSharp {
        fluid(maxWidth: 1024, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`
